<template>
  <div v-if="params.node.data[params.source]">
    <router-link
      v-if="params.type === 'staff'"
      :to="`/${params.type}/${params.node.data[params.source]}/edit`"
      >{{ params.value }}</router-link
    >
    <router-link
      v-else
      :to="`/${params.type}/${params.node.data[params.source]}`"
      >{{ params.value }}</router-link
    >
  </div>
</template>

<style lang="scss" scoped>
a {
  width: 100%;
  display: block;
  &:hover {
    color: var(--hover-color);
  }
}
</style>