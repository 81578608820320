<template>
  <div>
    <span
      v-if="params.value === 'new' || params.value === 'need_approve'"
      class="new-label"
    >
      Требует проверки
    </span>
  </div>
</template>

<style lang="scss" scoped>
.new-label {
  background-color: var(--main-color);
  color: white;
  border-radius: 3px;
  padding: 5px 8px;
}
</style>
